/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { Header, Footer, Responsive } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';

import bg from 'assets/client/images/main-bg-desktop.jpg';
// import bgMob from 'assets/client/images/main-bg-mobile.png';
import mainLogoBgLeft from 'assets/client/images/main-logo-bg-left.png';

import { texts } from 'client/constants';
import ContentForm from 'client/components/common/ContentForm';
import AlreadyInSystemPopup from 'client/components/common/Popups/AlreadyInSystemPopup';
import ClosedActivityPopup from 'client/components/common/Popups/ClosedActivityPopup';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
|};

type State = {
	isShowAlreadyInSystemPopup: boolean,
};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = {
		isShowAlreadyInSystemPopup: false,
	};

	componentDidMount() {
		const isCreatedLead = localStorage.getItem('ytlm');

		if (isCreatedLead) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
			});
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	render() {
		const { className, transitionState } = this.props;
		const { isShowAlreadyInSystemPopup } = this.state;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const descriptionMobile = (
			<>
				ספורטאים וספורטאיות צעירים וצעירות, במסגרת פרויקט "בוקר טוב יותר" המשותף לקבוצת "ידיעות אחרונות" ותלמה,
				אנחנו מזמינים אתכם ואתכן לספר לנו על ההצלחה האישית שלכם/ן בענף בו אתם/ן עוסקים/ות, על הישג שהגעתם/ן אליו
				כשבחרתם/ן לקחת את היכולות שלכם/ן ולמנף את עצמכם/ן קדימה מה עשיתם/ן כדי להגיע אליו? כיצד מתמודדים עם
				אתגרים בדרך להצלחה, איך מתמידים ולא מוותרים וממשיכים קדימה בנחישות?
			</>
		);
		return (
			<div className={classNames(css.intro, css[transitionState], className)}>
				<Header isMobile={Responsive.isMatching(Responsive.MOBILE)} />
				<div className={css.content} style={{ backgroundImage: `url(${bg})` }}>
					<div className={css.contentInner}>
						<div className={`${css.mainLogo} `}>
							<img className={css.mainLogoBgLeft} src={mainLogoBgLeft} alt="" />
							<div className={css.logoRed}>
								סיפור ההצלחה <span>שלי</span>
							</div>
						</div>
						<div className={css.mainContent}>
							<h1 className={css.title}>{texts.introTitle}</h1>
							<p className={css.description}>
								{!isMobile ? (
									<>
										ספורטאים וספורטאיות צעירים וצעירות, במסגרת פרויקט "בוקר טוב יותר" המשותף לקבוצת
										"ידיעות אחרונות" ותלמה, אנחנו מזמינים אתכם ואתכן לספר לנו על ההצלחה האישית
										שלכם/ן בענף בו אתם/ן עוסקים/ות, על
										<br />
										הישג שהגעתם/ן אליו כשבחרתם/ן לקחת את היכולות שלכם/ן ולמנף את עצמכם/ן קדימה
										<br /> מה עשיתם/ן כדי להגיע אליו? כיצד מתמודדים עם אתגרים בדרך להצלחה, איך
										מתמידים ולא מוותרים
										<br /> וממשיכים קדימה בנחישות?{' '}
									</>
								) : (
									descriptionMobile
								)}
							</p>
							<p className={css.description2}>
								הסיפורים הנבחרים יוצגו בכתבות וידיאו וטקסט באתר
								<br />
								<span> ynet </span>
								וב"ידיעות אחרונות".
							</p>
							<ContentForm />
						</div>
					</div>
				</div>
				<Footer />
				{/* <AlreadyInSystemPopup className={isShowAlreadyInSystemPopup ? 'show' : ''} /> */}
				<ClosedActivityPopup className="show" />
			</div>
		);
	}
}

export default withTransition(Intro);
